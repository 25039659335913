import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import BgImage from "../components/BgImage"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Liner from "../components/Liner"
import MastWrap from "../components/MastWrap"
import PageHeading from "../components/PageHeading"

export default function Home({ data }) {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 769px)`,
    },
  ]

  const DetailHeading = styled.h2`
     {
      color: #121212;
      font-family: "Montserrat", sans-serif;
      font-size: 48px;
      line-height: 58px;
      letter-spacing: 8px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  `

  return (
    <Layout>
      <MastWrap>
        <section>
          <div>
            <BgImage
              title="hero"
              fluid={sources}
              imgStyle={{ objectFit: "contain" }}
              height="100vh"
              overlayColor="#04040454"
            >
              <PageHeading heading="services" />
            </BgImage>
          </div>
        </section>

        <section className="sub-wrap pad-top pad-bottom about white-bg">
          <div className="container">
            <div className="row">
              <article className="col-md-6 text-left caption">
                <DetailHeading>the trial</DetailHeading>
                <Liner />
              </article>
              <article className="col-md-6 text-left">
                <p className="font4">
                  I always recommend a Trial before your wedding day or special
                  occasion*, for both makeup and hair styling, to ensure your
                  perfect look.
                  <br />
                  <br />
                  There is no time limit on the Trial (within reason) as I want
                  you to be completely happy with the results. If any of your
                  bridal or other party would like to be included in the trial
                  session, I can offer this service as well.
                  <br />
                  <br />
                  If you have the perfect look in mind, but don't quite know how
                  to achieve it yourself, or if you need ideas, then I can help
                  design the perfect look for you. It is quite helpful to
                  collect pictures of makeup and hair that you love as we can
                  work from them and get the perfect look together.
                  <br />
                  <br />
                  The Trial can take place in the comfort of your own home or
                  chosen venue to suit you.
                  <br />
                  <br />
                  If you would prefer not to have a trial for your special
                  occasion I am happy to discuss your chosen look over various
                  medias.
                  <br />
                  <br />
                  <span style={{ fontStyle: "italic" }}>
                    *The special occasion can be anything from a Prom night,
                    Parties, Red Carpet/Corporate Events, Birthdays or any other
                    occasion where you want to feel extra special.
                  </span>
                </p>
              </article>
            </div>
            <div className="row pad-top">
              <article className="col-md-6 text-left caption">
                <DetailHeading>the makeup</DetailHeading>
                <Liner />
              </article>
              <article className="col-md-6 text-left">
                <p className="font4">
                  We will discuss the colour and theme of your wedding/special
                  occasion. This is how we decide on what makeup you will feel
                  comfortable wearing and I will adjust the style and colours as
                  we progress throughout to ensure you feel confident and happy.
                  <br />
                  <br />I use two techniques for applying foundation. Firstly, I
                  am qualified in airbrushing and can apply your foundation
                  using this technique which is very long-lasting and looks
                  extremely natural.
                  <br />
                  <br />
                  Alternatively, I can use the conventional way of applying
                  foundation with a range of tools and techniques that still
                  creates the perfect look.
                </p>
              </article>
            </div>
            <div className="row pad-top">
              <article className="col-md-6 text-left caption">
                <DetailHeading>the hair</DetailHeading>
                <Liner />
              </article>
              <article className="col-md-6 text-left">
                <p className="font4">
                  We will discuss how you would like to wear your hair, what
                  accessories you are having and the overall style of your
                  wedding/special occasion.
                  <br />
                  <br />
                  Having the accessories available at the trial is helpful so
                  you can see how they work with the hairstyle, but don't worry
                  if the accessories are not available, we can still do the
                  trial without these.
                  <br />
                  <br />
                  I have many products that will help achieve your perfect style
                  and can offer advice on hair lengths, extensions, volume and
                  textures to achieve this.
                  <br />
                  <br />
                </p>
              </article>
            </div>
          </div>
        </section>

        <Footer />
      </MastWrap>
    </Layout>
  )
}

export const query = graphql`
  query Services {
    mobileImage: file(relativePath: { eq: "bg/10.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    desktopImage: file(relativePath: { eq: "bg/10.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`
